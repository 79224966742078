const offers_oliva = [
    {
      name: 'Relazioni Italiane',
      image: 'img/content-nore.png',
      gradientStart: '#ff6b6b', // Rosso acceso
      gradientEnd: '#f06595',   // Rosa acceso
      description: "Conferma email non necessaria - Italia solo",
      link: 'https://t.antj.link/333712/6733?aff_sub=Olivia&bo=2753,2754,2755,2756&popUnder=true&aff_sub5=SF_006OG000004lmDN'
    },
    {
      name: 'Ragazze Sole',
      image: 'img/content-nore.png',
      gradientStart: '#74b9ff', // Azzurro
      gradientEnd: '#0984e3',   // Blu brillante
      description: "Conferma email non necessaria - Italia solo",
      link: 'https://t.antj.link/333712/8271/0?aff_sub=OLIVIA&bo=2753,2754,2755,2756&po=6456&aff_sub5=SF_006OG000004lmDN'
    },
    {
      name: 'Carine Amici',
      image: 'img/content-nore.png',
      gradientStart: '#55efc4', // Verde acqua
      gradientEnd: '#00b894',   // Verde smeraldo
      description: "Conferma email non necessaria - Italia solo",
      link: 'https://t.antj.link/333712/9060/0?aff_sub=OLIVIA&bo=2753,2754,2755,2756&po=6456&aff_sub5=SF_006OG000004lmDN'
    },
    {
      name: 'CAM4',
      image: 'img/content-nore.png',
      gradientStart: '#81ecec', // Verde menta
      gradientEnd: '#00cec9',   // Verde acqua scuro
      description: "Conferma email  necessaria",
      link: 'https://t.ajrkm.link/333712/5409/0?aff_sub=OLIVIA&bo=2779,2778,2777,2776,2775&po=6533&aff_sub5=SF_006OG000004lmDN'
    },
    {
      name: 'ManFinder',
      image: 'img/content-nore.png',
      gradientStart: '#fab1a0', // Pesca
      gradientEnd: '#e17055',   // Arancione tenue
      description: "Conferma email non necessaria - WORLDWIDE",
      link: 'https://t.antj.link/333712/6488?aff_sub=OLIVIA&bo=2753,2754,2755,2756&popUnder=true&aff_sub5=SF_006OG000004lmDN'
    },
    {
      name: 'Adult Dating',
      image: 'img/content-nore.png',
      gradientStart: '#74b9ff', // Azzurro chiaro
      gradientEnd: '#0984e3',   // Blu vivace
      description: "Conferma email necessaria - WORLDWIDE",
      link: 'https://go-redirect.com/p/trli/taBX/0OaU?ml_sub2=OLIVIA'
    },
    {
      name: 'Jerkmate',
      image: 'img/content-nore.png',
      gradientStart: '#6d5ce7', // Viola intenso
      gradientEnd: '#a29afe',   // Lavanda
      description: "Conferma email necessaria - WORLDWIDE",
      link: 'https://t.ajrkm3.com/333712/8865/33286?aff_sub=OLIVIA&bo=2779,2778,2777,2776,2775&po=6533&aff_sub5=SF_006OG000004lmDN'
    }
];


const offers_laura = [
  {
    name: 'Relazioni Italiane',
    image: 'img/content-nore.png',
    gradientStart: '#6c5ce7', // Viola
    gradientEnd: '#a29bfe',   // Lavanda chiaro
    description: "Conferma email non necessaria - Italia solo",
    link: 'https://t.antj.link/333712/6733?aff_sub2=LAURA&bo=2753,2754,2755,2756&popUnder=true&aff_sub5=SF_006OG000004lmDN'
  },
  {
    name: 'Ragazze Sole',
    image: 'img/content-nore.png',
    gradientStart: '#00b894', // Verde smeraldo
    gradientEnd: '#55efc4',   // Verde chiaro
    description: "Conferma email non necessaria - Italia solo",
    link: 'https://t.antj.link/333712/8271/0?aff_sub2=LAURA&bo=2753,2754,2755,2756&po=6456&aff_sub5=SF_006OG000004lmDN'
  },
  {
    name: 'Carine Amici',
    image: 'img/content-nore.png',
    gradientStart: '#ff7675', // Rosa corallo
    gradientEnd: '#d63031',   // Rosso acceso
    description: "Conferma email non necessaria - Italia solo",
    link: 'https://t.antj.link/333712/9060/0?aff_sub2=LAURA&bo=2753,2754,2755,2756&po=6456&aff_sub5=SF_006OG000004lmDN'
  },
  {
    name: 'CAM4',
    image: 'img/content-nore.png',
    gradientStart: '#00cec9', // Verde acqua scuro
    gradientEnd: '#81ecec',   // Verde menta
    description: "Conferma email necessaria",
    link: 'https://t.ajrkm.link/333712/5409/0?aff_sub2=LAURA&bo=2779,2778,2777,2776,2775&po=6533&aff_sub5=SF_006OG000004lmDN'
  },
  {
    name: 'ManFinder',
    image: 'img/content-nore.png',
    gradientStart: '#e17055', // Arancione tenue
    gradientEnd: '#fab1a0',   // Pesca
    description: "Conferma email non necessaria - WORLDWIDE",
    link: 'https://t.antj.link/333712/6488?aff_sub2=LAURA&bo=2753,2754,2755,2756&popUnder=true&aff_sub5=SF_006OG000004lmDN'
  },
  {
    name: 'Adult Dating',
    image: 'img/content-nore.png',
    gradientStart: '#6c5ce7', // Viola intenso
    gradientEnd: '#a29bfe',   // Lavanda
    description: "Conferma email necessaria - WORLDWIDE",
    link: 'https://go-redirect.com/p/trli/taBX/0OaU?ml_sub1=Laura'
  },
  {
    name: 'Jerkmate',
    image: 'img/content-nore.png',
    gradientStart: '#6d5ce7', // Viola intenso
    gradientEnd: '#a29afe',   // Lavanda
    description: "Conferma email necessaria - WORLDWIDE",
    link: 'https://t.ajrkm3.com/333712/8865/33286?aff_sub2=LAURA&bo=2779,2778,2777,2776,2775&po=6533&aff_sub5=SF_006OG000004lmDN'
  }
]


const offers_elena = [
  {
    name: 'Relazioni Italiane',
    image: 'img/content-nore.png',
    gradientStart: '#6c5ce7', // Viola
    gradientEnd: '#a29bfe',   // Lavanda chiaro
    description: "Conferma email non necessaria - Italia solo",
    link: 'https://t.antj.link/333712/6733?aff_sub3=ELENA&bo=2753,2754,2755,2756&popUnder=true&aff_sub5=SF_006OG000004lmDN'
  },
  {
    name: 'Ragazze Sole',
    image: 'img/content-nore.png',
    gradientStart: '#00b894', // Verde smeraldo
    gradientEnd: '#55efc4',   // Verde chiaro
    description: "Conferma email non necessaria - Italia solo",
    link: 'https://t.antj.link/333712/8271/0?aff_sub3=ELENA&bo=2753,2754,2755,2756&po=6456&aff_sub5=SF_006OG000004lmDN'
  },
  {
    name: 'Carine Amici',
    image: 'img/content-nore.png',
    gradientStart: '#ff7675', // Rosa corallo
    gradientEnd: '#d63031',   // Rosso acceso
    description: "Conferma email non necessaria - Italia solo",
    link: 'https://t.antj.link/333712/9060/0?aff_sub3=ELENA&bo=2753,2754,2755,2756&po=6456&aff_sub5=SF_006OG000004lmDN'
  },
  {
    name: 'CAM4',
    image: 'img/content-nore.png',
    gradientStart: '#00cec9', // Verde acqua scuro
    gradientEnd: '#81ecec',   // Verde menta
    description: "Conferma email necessaria",
    link: 'https://t.ajrkm.link/333712/5409/0?aff_sub3=ELENA&bo=2779,2778,2777,2776,2775&po=6533&aff_sub5=SF_006OG000004lmDN'
  },
  {
    name: 'ManFinder',
    image: 'img/content-nore.png',
    gradientStart: '#e17055', // Arancione tenue
    gradientEnd: '#fab1a0',   // Pesca
    description: "Conferma email non necessaria - WORLDWIDE",
    link: 'https://t.antj.link/333712/8771/34991?aff_sub3=ELENA&bo=2753,2754,2755,2756&po=6456&aff_sub5=SF_006OG000004lmDN'
  },
  {
    name: 'Adult Dating',
    image: 'img/content-nore.png',
    gradientStart: '#6c5ce7', // Viola intenso
    gradientEnd: '#a29bfe',   // Lavanda
    description: "Conferma email necessaria - WORLDWIDE",
    link: 'https://go-redirect.com/p/trli/taBX/vJeM?ml_sub3=ELENA'
  },
  {
    name: 'Jerkmate',
    image: 'img/content-nore.png',
    gradientStart: '#6d5ce7', // Viola intenso
    gradientEnd: '#a29afe',   // Lavanda
    description: "Conferma email necessaria - WORLDWIDE",
    link: 'https://t.ajrkm3.com/333712/8865/33286?aff_sub3=ELENA&bo=2779,2778,2777,2776,2775&po=6533&aff_sub5=SF_006OG000004lmDN'
  }
]


const offers_chiara = [
  {
    name: 'Relazioni Italiane',
    image: 'img/content-nore.png',
    gradientStart: '#6c5ce7', // Viola
    gradientEnd: '#a29bfe',   // Lavanda chiaro
    description: "Conferma email non necessaria - Italia solo",
    link: 'https://t.antj.link/333712/6733?aff_sub5=CHIARA&bo=2753,2754,2755,2756&popUnder=true'
  },
  {
    name: 'Ragazze Sole',
    image: 'img/content-nore.png',
    gradientStart: '#00b894', // Verde smeraldo
    gradientEnd: '#55efc4',   // Verde chiaro
    description: "Conferma email non necessaria - Italia solo",
    link: 'https://t.antj.link/333712/8271/0?aff_sub5=CHIARA&bo=2753,2754,2755,2756&po=6456'
  },
  {
    name: 'Carine Amici',
    image: 'img/content-nore.png',
    gradientStart: '#ff7675', // Rosa corallo
    gradientEnd: '#d63031',   // Rosso acceso
    description: "Conferma email non necessaria - Italia solo",
    link: 'https://t.antj.link/333712/9060/0?aff_sub5=CHIARA&bo=2753,2754,2755,2756&po=6456'
  },
  {
    name: 'Adult Dating',
    image: 'img/content-nore.png',
    gradientStart: '#6c5ce7', // Viola intenso
    gradientEnd: '#a29bfe',   // Lavanda
    description: "Conferma email necessaria - WORLDWIDE",
    link: 'https://go-redirect.com/p/trli/1uIQ/L9tu?ml_sub5=CHIARA'
  },
  {
    name: 'Jerkmate',
    image: 'img/content-nore.png',
    gradientStart: '#6d5ce7', // Viola intenso
    gradientEnd: '#a29afe',   // Lavanda
    description: "Conferma email necessaria - WORLDWIDE",
    link: 'https://t.ajrkm3.com/333712/8865/0?aff_sub4=CHIARA&bo=2779,2778,2777,2776,2775&po=6533&aff_sub5=SF_006OG000004lmDN'
  },
  {
      name: 'CAM4',
      image: 'img/content-nore.png',
      gradientStart: '#81ecec', // Verde menta
      gradientEnd: '#00cec9',   // Verde acqua scuro
      description: "Conferma email  necessaria",
      link: 'https://t.ajrkm.link/333712/5409/0?aff_sub4=CHIARA&bo=2779,2778,2777,2776,2775&po=6533&aff_sub5=SF_006OG000004lmDN'
    },
    {
      name: 'ManFinder',
      image: 'img/content-nore.png',
      gradientStart: '#fab1a0', // Pesca
      gradientEnd: '#e17055',   // Arancione tenue
      description: "Conferma email non necessaria - WORLDWIDE",
      link: 'https://t.antj.link/333712/6488?aff_sub4=CHIARA&bo=2753,2754,2755,2756&popUnder=true&aff_sub5=SF_006OG000004lmDN'
    }
]

const offers_giorgia = [
  {
    name: 'Relazioni Italiane',
    image: 'img/content-nore.png',
    gradientStart: '#6c5ce7', // Viola
    gradientEnd: '#a29bfe',   // Lavanda chiaro
    description: "Conferma email non necessaria - Italia solo",
    link: 'https://t.antj.link/333712/6733?aff_sub4=GIORGIA&bo=2753,2754,2755,2756&popUnder=true&aff_sub5=SF_006OG000004lmDN'
  },
  {
    name: 'Ragazze Sole',
    image: 'img/content-nore.png',
    gradientStart: '#00b894', // Verde smeraldo
    gradientEnd: '#55efc4',   // Verde chiaro
    description: "Conferma email non necessaria - Italia solo",
    link: 'https://t.antj.link/333712/8271/0?aff_sub4=GIORGIA&bo=2753,2754,2755,2756&po=6456&aff_sub5=SF_006OG000004lmDN'
  },
  {
    name: 'Carine Amici',
    image: 'img/content-nore.png',
    gradientStart: '#ff7675', // Rosa corallo
    gradientEnd: '#d63031',   // Rosso acceso
    description: "Conferma email non necessaria - Italia solo",
    link: 'https://t.antj.link/333712/9060/0?aff_sub4=GIORGIA&bo=2753,2754,2755,2756&po=6456&aff_sub5=SF_006OG000004lmDN'
  },
  {
    name: 'Adult Dating',
    image: 'img/content-nore.png',
    gradientStart: '#6c5ce7', // Viola intenso
    gradientEnd: '#a29bfe',   // Lavanda
    description: "Conferma email necessaria - WORLDWIDE",
    link: 'https://go-redirect.com/p/trli/1uIQ/L9tu?ml_sub4=GIORGIA'
  },
  {
    name: 'Jerkmate',
    image: 'img/content-nore.png',
    gradientStart: '#6d5ce7', // Viola intenso
    gradientEnd: '#a29afe',   // Lavanda
    description: "Conferma email necessaria - WORLDWIDE",
    link: 'https://t.ajrkm3.com/333712/8865/33286?aff_sub4=GIORGIA&bo=2779,2778,2777,2776,2775&po=6533&aff_sub5=SF_006OG000004lmDN'
  },
   {
      name: 'CAM4',
      image: 'img/content-nore.png',
      gradientStart: '#81ecec', // Verde menta
      gradientEnd: '#00cec9',   // Verde acqua scuro
      description: "Conferma email  necessaria",
      link: 'https://t.ajrkm.link/333712/5409/0?aff_sub5=GIORGIA&bo=2779,2778,2777,2776,2775&po=6533'
    },
    {
      name: 'ManFinder',
      image: 'img/content-nore.png',
      gradientStart: '#fab1a0', // Pesca
      gradientEnd: '#e17055',   // Arancione tenue
      description: "Conferma email non necessaria - WORLDWIDE",
      link:   'https://t.antj.link/333712/6488?aff_sub5=GIORGIA&bo=2753,2754,2755,2756&popUnder=true'
    }
]
module.exports = {
  offers_oliva,
  offers_laura,
  offers_elena,
  offers_chiara,
  offers_giorgia

}

